import { black, brand, white } from '@pelotoncycle/design-system';
import clsx from 'clsx';
import { transparentize } from 'polished';
import type { PropsWithChildren } from 'react';
import React from 'react';
import ReactModal from 'react-modal';
import styled, { css } from 'styled-components';
import type { PropsOf } from '@peloton/react';
import { defaultTransition, hoverTransition, media } from '@peloton/styles';
import { CloseIcon } from '@ecomm/icons';
import { focusHighlight } from '@ecomm/styles';
import type { BaseModalProps } from './BaseModal';
import BaseModal from './BaseModal';
import ModalGlobalStyles from './ModalGlobalStyles';

export enum ModalWidth {
  Narrow = 460,
  Normal = 780,
  Medium = 864,
  SemiWide = 960,
  Wide = 1024,
  ExtraWide = 1280,
  MaxWide = 1400,
}

ReactModal.defaultStyles.overlay = {
  ...ReactModal.defaultStyles.overlay,
  backgroundColor: `${transparentize(0.25, black)}`,
  height: '100%',
  padding: 0,
  zIndex: 99999,
};

// http://reactcommunity.org/react-modal/accessibility/#app-element
if (typeof window !== 'undefined') {
  const root = document.getElementById('__next') ?? document.getElementById('root');
  ReactModal.setAppElement(root as HTMLElement);
}

const StyledModal: React.FunctionComponent<
  React.PropsWithChildren<PropsOf<typeof ReactModal> & StyledModalProps>
> = styled(ReactModal)`
  align-items: center;
  text-align: center;
  justify-content: center;
  max-width: ${({ width }: StyledModalProps) => width}px;
  width: ${({ useFlexibleWidth }: StyledModalProps) =>
    useFlexibleWidth ? 'auto' : '100%'};
  outline: none;

  --ecomm-modal-margin-bottom: 40px;
  --ecomm-modal-margin-top: 0px;

  margin-bottom: 40px;
  ${media.iPhone6`
    margin: 0 20px 40px 20px;
  `}
  ${media.tablet`
    --ecomm-modal-margin-bottom: 80px;
    --ecomm-modal-margin-top: 20px;
    margin: 20px 40px 80px;
  `}

  ${media.desktop`
    --ecomm-modal-margin-bottom: 90px;
    --ecomm-modal-margin-top: 30px;
    margin: 30px 0 90px;
  `}
`;

const StyledCloseIcon = styled(CloseIcon)`
  ${defaultTransition('height, width', 50, 'linear')}
  display: block;
  fill: ${white};
  height: 16px;
  width: 16px;

  ${media.tablet`
    height: 20px;
    width: 20px;
  `}
`;

const CloseIconContainer = styled.div`
  cursor: pointer;

  ${media.tablet`
    ${hoverTransition({
      property: 'opacity',
      from: '.8',
      to: '1',
    })}
  `}
`;

const CloseButton = styled.button`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: flex-end;
  width: 100%;
  padding-right: 5px;
  cursor: default;

  ${focusHighlight}
`;

const modalBorderStyles = css`
  border-radius: 5px;
  overflow-x: hidden;
`;

const ModalContentContainer = styled.div`
  background-color: ${brand.light};
  ${modalBorderStyles};
  height: 100%;
  width: 100%;

  ${({ padded = true }: { padded?: boolean }) =>
    padded
      ? `
        padding: 40px 20px;

        ${media.tablet`
          padding: 40px 60px;
        `}
      `
      : ''}
`;

/*
 * This fixes a silly iOS bug where the cursor is put outside of the input.
 *
 * The bug happens only to the first fixed element on the page, so putting an empty fixed
 * element on the page before the modal fixes the issue.
 *
 */
const Fixed = styled.div`
  background: white;
  height: 1px;
  position: fixed;
  top: -1px;
  width: 100%;
`;

class Modal extends BaseModal<PropsWithChildren<ModalProps & Partial<DispatchProps>>> {
  public render() {
    const {
      isOpen = true,
      width,
      contentLabel,
      closeHandler,
      reactModalStyle,
      useFlexibleWidth,
      autoFocusCloseButton = false,
      appElement,
      contentClassname,
      contentContainerClassname,
      ...props
    } = this.props;
    /*
     * NOTE: DO NOT WRAP THE StyledModal COMPONENT IN ANY DOM ELEMENTS!
     *
     * The StyledModal uses a portal to attach its elements to a different part of the DOM.
     * Wrapping the StyledModal in a DOM element here will leave the element in a spot in the DOM
     * that is likely different from where you are expecting it to go.
     */

    return (
      <StyledModal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={true}
        contentLabel={contentLabel}
        onAfterOpen={this.handleAfterOpen}
        onRequestClose={this.handleRequestClose}
        closeTimeoutMS={400}
        style={reactModalStyle}
        width={width || ModalWidth.Normal}
        useFlexibleWidth={useFlexibleWidth}
        role="dialog"
        aria={{ modal: 'true' } as ReactModal.Aria}
        appElement={appElement}
        className={contentClassname}
      >
        <ModalGlobalStyles />
        <Fixed /> {/* input-cursor fix; see comment on component declaration */}
        {/* eslint-disable styled-components-a11y/no-autofocus */}
        {/* while autoFocus is generally a violation, in this case, it actually *fixes* an issue where the focus trap isn't set within the lightbox modal on iOS Safari */}
        <CloseButton
          autoFocus={autoFocusCloseButton}
          onClick={this.handleRequestClose}
          data-test-id="dismissModal"
          aria-label="Close"
        >
          <CloseIconContainer>
            <StyledCloseIcon />
          </CloseIconContainer>
        </CloseButton>
        {/* eslint-enable styled-components-a11y/no-autofocus */}
        <ModalContentContainer
          {...props}
          className={clsx(contentContainerClassname, props.className)}
        />
      </StyledModal>
    );
  }

  handleAfterOpen = () => {
    this.props.modalOpened?.();
    this.props.openHandler && this.props.openHandler();

    const rootElement = getRootElement();
    // Apply the saved 'scroll' position to the #root element when the modal is opened
    document.body.style.width = `100%`;
    document.body.style.top = rootElement
      ? `${rootElement.getBoundingClientRect().y}px`
      : '0px';
    document.body.style.position = 'fixed';
  };

  handleRequestClose = () => {
    this.props.closeHandler && this.props.closeHandler();
    this.props.modalClosed?.();
    this.applySavedScrollPosition();
  };
}

const getRootElement = (): HTMLElement | null => {
  const craRootElement = document.getElementById('root');
  const nextRootElement = document.getElementById('__next');
  return craRootElement || nextRootElement;
};

type StyledModalProps = {
  width?: ModalWidth;
  useFlexibleWidth?: boolean;
};

export type ModalProps = BaseModalProps &
  StyledModalProps & {
    autoFocusCloseButton?: boolean;
    className?: string;
    padded?: boolean;
    reactModalStyle?: ReactModal.Styles;
    appElement?: HTMLElement;
    contentClassname?: string;
    contentContainerClassname?: string;
    id?: string;
  };

type DispatchProps = {
  modalOpened: () => void;
  modalClosed: () => void;
};

export default Modal;
