import React from 'react';
import { useLocale } from '@peloton/internationalize';
import type { StudioFaq } from '@studio/copy';
import { Layout } from '@studio/layout';
import { trackViewedSupportPage } from './analytics';
import Contact from './Contact';
import Faqs from './Faqs';

const SupportPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const teamupFaqIds: StudioFaq[] = [
    'studioSite.support.faq.studioRules.teamup',
    'studioSite.support.faq.newStudioBookingSystem.teamup',
    'studioSite.support.faq.general.teamup',
    'studioSite.support.faq.booking.teamup',
    'studioSite.support.faq.pricing.teamup',
    'studioSite.support.faq.events.teamup',
  ];

  const locale = useLocale();

  if (locale !== 'de-DE') {
    teamupFaqIds.push('studioSite.support.faq.general.londonCalling');
  }

  React.useEffect(() => {
    trackViewedSupportPage();
  }, []);

  return (
    <Layout>
      <Contact />
      {teamupFaqIds.map(id => (
        <Faqs key={id} id={id} />
      ))}
    </Layout>
  );
};

export default SupportPage;
